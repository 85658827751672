<template>
  <section>
    <v-card
      class="mx-10 mt-10"
      flat
      v-if="assessment.questions_count < assessment.item"
    >
      <v-row class="mb-0 pb-0">
        <v-col cols="12" lg="6">
          <label class="caption">TYPE *</label>
          <v-select
            solo
            :items="$helpers.assessmentType()"
            item-text="text"
            item-value="value"
            :error-messages="errors.type"
            v-on:input="selectType($event)"
            v-model="type"
          ></v-select>
        </v-col>
        <v-col cols="12" lg="3">
          <label class="caption">SCORE</label>
          <v-text-field type="number" solo v-model="score" />
        </v-col>
        <v-col cols="12" lg="2">
          <label class="caption">ITEM</label>
          <div class="body-2">
            {{ assessment.questions_count + "/" + assessment.item }}
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0 pt-0">
        <v-col cols="12">
          <label class="caption">QUESTION *</label>
          <vue-editor
            v-model="question"
            :style="errors.question ? 'border: 1px solid red' : ''"
          ></vue-editor>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12" lg="8">
          <div class="d-flex mb-5 align-center">
            <v-btn v-if="!no_choices" @click="addChoices" color="red" dark
              >Add Choices</v-btn
            >
            <v-switch
              v-if="on_multiple"
              class="ml-5"
              v-model="multiple"
              label="Multiple Answer"
            />
          </div>

          <v-radio-group
            v-if="choices.length > 0 && type != 'sur'"
            v-model="answer"
            mandatory
          >
            <div class="d-flex" v-for="(item, i) in choices" :key="i">
              <v-radio :value="item"></v-radio>
              <v-text-field
                :readonly="type == 'tof'"
                class="pt-5"
                solo
                v-model="choices[i]"
                append-icon="mdi-close"
                @click:append="remove(i)"
              />
            </div>
          </v-radio-group>

          <div v-if="choices.length > 0 && type == 'sur'">
            <v-text-field
              v-for="(item, i) in choices"
              :key="i"
              solo
              v-model="choices[i]"
              append-icon="mdi-close"
              @click:append="remove(i)"
            />
          </div>

          <div v-if="type == 'iden'">
            <label class="caption">ANSWER *</label>
            <v-textarea solo row="2" v-model="answer"></v-textarea>
          </div>
        </v-col>
      </v-row>
      <v-card-actions class="d-flex justify-end">
        <v-btn @click="save" color="primary">Save Question</v-btn>
      </v-card-actions>
    </v-card>

    <v-alert v-else type="warning" max-width="400" class="ma-10">
      Question limit exceeded.
    </v-alert>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["assessment"],
  data: () => ({
    type: "",
    no_choices: true,
    choices: [],
    setter: [],
    question: "",
    score: "",
    answer: "",
    multiple: false,
    on_multiple: false,
  }),
  computed: {
    ...mapState("teacher", {
      errors: (state) => state.errors,
    }),
  },
  methods: {
    ...mapActions("teacher", ["addQuestionAction"]),

    selectType(e) {
      this.choices = [];
      this.answer = "";
      this.on_multiple = false;
      this.multiple = false;
      //--- TRUE OR FALSE
      if (e == "tof") {
        this.choices.push("True", "False");
      }
      if (e == "sur") {
        this.on_multiple = true;
        this.no_choices = false;
        return;
      }
      if (e == "mc") {
        this.no_choices = false;
        this.choices.push("Type your answer " + (this.choices.length + 1));
        return;
      }

      this.no_choices = true;
    },

    addChoices() {
      if (this.type == "mc" || this.type == "sur") {
        this.choices.push("Type your answer " + (this.choices.length + 1));
        return;
      }
      alert("Select type of question first!");
    },

    save() {
      console.log(this.choices);
      this.addQuestionAction({
        assessment_id: this.assessment.id,
        uuid: this.assessment.uuid, // for query after saving
        question: this.question,
        choices: this.choices,
        type: this.type,
        answer: this.answer,
        score: this.score,
        is_multiple: this.multiple,
      }).then(() => {
        if (this.$errors("teacher")) return;
        this.$emit("save", "Question Created!");
        this.reset();
      });
    },
    //---REMOVE FROM CHOICES
    remove(i) {
      this.choices.splice(i, 1);
    },

    reset() {
      this.question = "";
      this.choices = [];
      this.answer = "";
      this.type = "";
      this.no_choices = true;
      this.score = "";
      this.multiple = false;
      this.on_multiple = false;
    },
  },
};
</script>