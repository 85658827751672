<template>
  <section>
    <v-row v-if="!loading">
      <v-col cols="12" xl="8" lg="10">
        <v-card>
          <v-card-title class="d-flex" style="height: 70px">
            <v-btn large icon class="mr-3 mt-1" @click="$router.back()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div class="text-h6">
              {{ assessment.title }}
            </div>
            <v-spacer />
          </v-card-title>
          <v-divider />
          <v-row no-gutters>
            <v-col cols="12" lg="8">
              <v-card-text>
                <v-tabs color="primary" v-model="tab">
                  <v-tab class="caption" v-for="item in items" :key="item">
                    {{ item }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="item in items" :key="item">
                    <EditAssessment
                      v-if="item == 'Assessment'"
                      :assessment="assessment"
                    />

                    <Questions
                      v-else-if="item == 'Assessment Builder'"
                      :assessment="assessment"
                      @save="saveQuestion"
                    />

                    <Questionnaire
                      v-else
                      :assessment="assessment"
                      @save="saveQuestion"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-col>
            <v-col cols="12" lg="4" class="secondary">
              <div class="body-2 mt-6 ml-10">ASSESSMENT TIMELINE</div>
              <v-timeline align-top dense class="my-5">
                <v-timeline-item
                  v-for="item in assessment.questions"
                  :key="item.id"
                  color="success"
                  small
                >
                  <v-row class="pt-1 pr-8">
                    <v-col>
                      <span v-html="item.question"></span>
                      <div class="caption" style="margin-top: -15px">
                        {{ $helpers.assessment(item.type) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <Snackbar
      :snack="snack.snack"
      :text="snack.text"
      timeout="2000"
      @close="snack.snack = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EditAssessment from "../components/assessment/EditAssessment.vue";
import Questions from "../components/assessment/Questions.vue";
import Questionnaire from "../components/assessment/Questionnaire.vue";
import Snackbar from "../../constants/Snackbar.vue";
export default {
  components: {
    EditAssessment,
    Questions,
    Questionnaire,
    Snackbar,
  },
  data: () => ({
    loading: true,
    tab: null,
    snack: {
      snack: false,
      text: "",
    },
    items: ["Assessment", "Assessment Builder", "Questionnaire"],
  }),
  computed: {
    ...mapState("teacher", {
      assessment: (state) => state.assessment,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions("teacher", ["showAssessmentAction"]),

    fetch() {
      this.showAssessmentAction(this.$route.params.uuid).then(() => {
        this.loading = false;
      });
    },
    saveQuestion(e) {
      console.log(e);
      this.snack.snack = true;
      this.snack.text = e;
    },
  },
  beforeDestroy() {
    this.$store.commit("teacher/assessmentMutation", []);
  },
};
</script>