<template>
  <section class="mx-5 mt-10 mb-5">
    <v-expansion-panels>
      <v-expansion-panel
        v-for="item in assessment.questions"
        :key="item.id"
        class="rounded my-2"
      >
        <v-expansion-panel-header
          class="d-flex justify-space-between align-center"
        >
          <div class="body-1 font-weight-bold" v-html="item.question" />
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-5">
          <vue-editor v-model="item.question"></vue-editor>
          <v-row>
            <v-col cols="12" lg="8">
              <div class="d-flex mt-5 align-center">
                <v-btn
                  v-if="item.type == 'mc' || item.type == 'sur'"
                  @click="addChoices(item.id)"
                  color="primary"
                >
                  Add Choices
                </v-btn>

                <v-switch
                  v-if="item.type == 'sur'"
                  class="ml-5"
                  color="success"
                  v-model="item.is_multiple"
                  label="Multiple Answer"
                />
              </div>

              <v-radio-group
                v-if="item.type != 'iden' || item.type != 'ess'"
                v-model="item.answer"
                mandatory
              >
                <div
                  class="d-flex align-center"
                  v-for="(choice, i) in item.choices"
                  :key="i"
                >
                  <v-radio
                    color="success"
                    :value="choice"
                    style="margin-top: -15px"
                    v-if="item.type != 'sur'"
                  ></v-radio>
                  <v-text-field
                    :readonly="item.type == 'tof'"
                    solo
                    v-model="item.choices[i]"
                    :append-icon="item.type == 'tof' ? '' : 'mdi-close'"
                    @click:append="remove(item.id, i)"
                  />
                </div>
              </v-radio-group>

              <div class=""></div>
            </v-col>
          </v-row>
          <v-btn color="primary" outlined dark @click="save(item.id)">Save changes</v-btn>
          <v-btn color="danger" text @click="del(item.id)">Delete</v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  props: ["assessment"],
  methods: {
    ...mapActions("teacher", ["updateQuestionAction",'deleteQuestionAction']),
    ...mapMutations("teacher", ["addChoicesMutation", "removeChoiceMutation"]),

    addChoices(id) {
      console.log(id);
      this.addChoicesMutation(id);
    },
    remove(id, index) {
      this.removeChoiceMutation({
        id: id,
        index: index,
      });
    },
    save(id) {
      let vm = this;
      vm.assessment.questions.forEach((el) => {
        if (el.id == id) {
          vm.updateQuestionAction({
            uuid: vm.assessment.uuid,
            question_id: el.id,
            choices: el.choices,
            question: el.question,
            answer: el.answer,
            score: el.score,
            is_multiple: el.is_multiple,
          }).then(() => {
            this.$emit("save", "Question Updated");
            return;
          });
        }
      });
    },

    del(id) {
      if(confirm('Delete this question?')) {
        this.deleteQuestionAction({
          uuid: this.assessment.uuid,
          id: id
        })
      }
    }
  },
};
</script>