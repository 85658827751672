<template>
  <section class="mx-5 mt-10">
    <v-form ref="addForm" @submit.prevent="save" class="mt-5 mx-2">
      <label class="caption">TITLE *</label>
      <v-text-field
        solo
        v-model="assessment.title"
        :error-messages="errors.title"
      />
      <v-row>
        <v-col cols="6">
          <label class="caption">TIME (in minutes)</label>
          <v-text-field
            type="number"
            solo
            :error-messages="errors.time"
            v-model="assessment.time"
          />
        </v-col>
        <!-- <v-col cols="4">
          <label class="caption">SCORE</label>
          <v-text-field type="number" solo 
          :error-messages="errors.score"
          v-model="assessment.score"/>
        </v-col> -->
        <v-col cols="6">
          <label class="caption">ITEMS *</label>
          <v-text-field
            type="number"
            solo
            :error-messages="errors.item"
            v-model="assessment.item"
          />
        </v-col>
      </v-row>
      <label class="caption">DESCRIPTION</label>
      <v-textarea solo v-model="assessment.description"></v-textarea>
      <v-btn color="red" dark :type="!loading ? 'submit' : ''" class="">{{
        !loading ? "Save Changes" : "Saving..."
      }}</v-btn>
    </v-form>

    <snackbar
      :snack="snack"
      text="Assessment updated!"
      timeout="2000"
      @close="snack = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["assessment"],
  data: () => ({
    loading: false,
    snack: false,
  }),
  computed: {
    ...mapState("teacher", {
      errors: (state) => state.errors,
    }),
  },
  methods: {
    ...mapActions("teacher", ["updateAssessmentAction"]),

    save() {
      this.updateAssessmentAction(this.assessment).then(() => {
        if (this.$errors("teacher")) return;
        this.snack = true;
      });
    },
  },
};
</script>